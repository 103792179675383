import {
  DownOutlined,
  LeftCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons'
import { Col, Dropdown, MenuProps, Row, Table } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { Button } from 'ui'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'
import { ToggleCarrierPreference } from 'trellis:api/carrier/carrierApi'
import { NotifyText } from 'trellis:constants/notifyText'
import { IAuthenticatedApiModel } from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'

import { SupportedService } from './_carrierInfoTypes'
import {
  getColumns,
  getIcon,
  getTableData,
  handleSupportedServices,
} from './_carrierInfoUtilities'
import CarrierAttachment from './CarrierAttachment/CarrierAttachment'

import './CarrierInfo.scss'

import { ItemType } from 'antd5/es/menu/hooks/useItems'

// import { ItemType } from 'antd5/es/menu/interface'

import { formatZip } from 'utils'

interface CarrierProps {
  carrier: CarrierDto
  setCarrier: Dispatch<SetStateAction<CarrierDto>>
  authentication: IAuthenticatedApiModel
  handleCarrierPreferenceChange: (
    record: CarrierDto,
    isFavorite: boolean,
  ) => void
}

export const getCarrierAddress = (carrier: CarrierDto) => {
  return carrier.PrimaryAddress1
    ? `${carrier.PrimaryAddress1}${carrier.PrimaryAddress2 ? ' ' + carrier.PrimaryAddress2 : ''} ${carrier.PrimaryCity}, ${carrier.PrimaryState} ${formatZip(carrier.PrimaryZip)}`
    : ''
}

export const CarrierInfo: FC<CarrierProps> = ({
  carrier,
  setCarrier,
  authentication,
  handleCarrierPreferenceChange,
}) => {
  const [dropdownText, setDropdownText] = useState('Clearinghouse ID')
  const [isFavorite, setIsFavorite] = useState(carrier.IsFavorite)

  const addressText = getCarrierAddress(carrier)

  const supportedServices: SupportedService[] = [
    { label: 'Real-Time Claims', icon: getIcon(carrier.RtClaims) },
    { label: 'Batch Claims', icon: getIcon(carrier.BatchClaims) },
    { label: 'Eligibility', icon: getIcon(carrier.Eligibility) },
    { label: 'Attachments', icon: getIcon(carrier.NeaMasterId) },
    { label: 'Electronic Remittance', icon: getIcon(carrier.Era) },
  ]

  const toggleFavoriteStatus = async () => {
    await ToggleCarrierPreference(carrier.Id)
      .then(() => {
        handleCarrierPreferenceChange(carrier, !isFavorite)

        if (!isFavorite)
          showMessage(NotifyText.carrierAddedAsFavorite, 'success')
        else showMessage(NotifyText.carrierRemovedAsFavorite, 'success')

        setIsFavorite(!isFavorite)
      })
      .catch(() => {
        showMessage(NotifyText.carrierPreferenceFail)
      })
  }

  const onDropdownClick: MenuProps['onClick'] = ({ key }) => {
    setDropdownText(menuItems.find((item) => item.key === key)['label'])
  }

  const menuItems: ItemType[] = [
    { key: '1', label: `Change Healthcare - ${carrier.ChcPayerId || 'N/A'}` },
    { key: '2', label: `DentalXChange - ${carrier.DxcPayerId || 'N/A'}` },
  ]

  return (
    <div>
      <Row>
        <Col className='carrier-info--back-button'>
          <LeftCircleOutlined
            className='back-to-all float-left fs-150'
            onClick={() => setCarrier(null)}
          />
          <span className='float-left pl-050'>Back</span>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className='carrier-info--name'>{carrier.Name}</div>
          <div className='carrier-info--address'>{addressText}</div>
        </Col>
        <Col
          span={12}
          className='carrier-info--favorite'
        >
          <Button
            icon={
              isFavorite ? (
                <StarFilled className='carrier-info--favorite-star' />
              ) : (
                <StarOutlined className='carrier-info--favorite-no-star' />
              )
            }
            label={isFavorite ? 'Favorite' : 'Favorite Carrier'}
            size='middle'
            className='carrier-info--favorite-button'
            onClick={toggleFavoriteStatus}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='carrier-info--id'>
            <div className='carrier-info--id-text'>
              Vyne Carrier ID/E-claim ID:{' '}
              <span className='carrier-info--id-highlight'>
                {carrier.PayerTxtId}
              </span>
            </div>
            {/* TODO: this dropdown should probably be a select */}
            <Dropdown
              menu={{
                items: menuItems,
                onClick: onDropdownClick,
              }}
              trigger={['click']}
            >
              <Button
                className='carrier-info--id-button'
                label={dropdownText}
                type='default'
                size='small'
              >
                <DownOutlined style={{ width: 10, height: 10 }} />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col
          span={16}
          className='carrier-info__grid'
        >
          <Table
            columns={getColumns()}
            dataSource={getTableData(carrier)}
            pagination={false}
            rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
            size='middle'
          />
        </Col>
        <Col
          span={8}
          className='carrier-info__sidebar'
        >
          <h4>Services</h4>
          {handleSupportedServices(carrier, supportedServices).map(
            (service, index) => {
              return (
                <div
                  className='carrier-info__sidebar-item'
                  key={`carrier-service--${index}`}
                >
                  <div className='carrier-info__sidebar-item-text'>
                    {service.label}
                  </div>
                  {service.icon}
                </div>
              )
            },
          )}
        </Col>
      </Row>
      <CarrierAttachment carrier={carrier} />
    </div>
  )
}
